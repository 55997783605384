
.hidden-scrollbar{
  overflow: auto;
  scrollbar-width: none;
}






.hidden-scrollbar::-webkit-scrollbar{
  display: none;
}

.custom-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(124, 121, 121, 0.7);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 14px !important;
  font-weight: 600;
  color: #fff;
}

.modalIndex{
  z-index: 9000 !important;
}


.slide img{
  border-radius: 20px !important;
}

.slide{
  transform: scale(0.7);
  transition:transfrom 300ms;
  opacity: 1;
  transition: all 0.5s ease;
  transform-origin: bottom center;
  overflow: visible !important;
  padding-top: 80px;
  height: 540px;
  width: 100%;
  background-color: #01554F;
  border-radius: 20px;
}



.activeSlide{
  transform:scale(1) !important ;
  opacity: 2 !important;
  border-radius: 20px !important;

}


.custom-arrow {
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  z-index: 10;
}

.custom-arrow-prev {
  left:10%;
}

.custom-arrow-next {
  right: 10%;
}

.custom-arrow-banner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}



.custom-arrow-prev-banner {
  left:3%;
}

.slick-dots li.slick-active div {
  background: #FFFFFF !important;
  width: 60px !important;
}



.custom-arrow-next-banner {
  right: 3%;
}
@media screen and (max-width: 768px) {
    .activeSlide {
      transform:scale(1) !important ;
      opacity: 2 !important;
      border-radius: 20px !important;
    }
  
  .custom-arrow {
    position: absolute;
    top: 110%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .custom-arrow-next {
    left: 55%;
  }
  .custom-arrow-prev {
    left:35%;
  }
  .slide{
    height: 350px;
    width: 100%;
  }
  .custom-arrow-banner {
    display: none !important;

  }
}


@keyframes gradientAnimation {
  0% {
    background-position: 100% 10%;
  }
  50% {
    background-position: 10% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}