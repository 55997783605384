@tailwind base;
@tailwind components;
@tailwind utilities;



:root{
   --header-height:75px;
}


* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html,
body {
   font-family: "Rubik", serif;
   padding-top: var(--header-height);
}


@media (max-width: 1108px) { 
   :root {
     --header-height: 28px; 
   }
 }
 
 @media (max-width: 768px) { 
   :root {
     --header-height: 28px; 
   }
 }
